import axios from 'axios'
require('dotenv').config()

const client = axios.create({
  baseURL: process.env.VUE_APP_NODEJS_SERVER_HOST,
  json: true
})

export default {
  async execute (method, resource, data) {
    return client({
      method,
      url: resource,
      data,
      headers: {
      }
    }).then(req => {
      return req.data
    })
  },
  getGoals () {
    return this.execute('get', '/goals')
  },
  getTasks () {
    return this.execute('get', '/tasks')
  },
  getGoal (id) {
    return this.execute('get', `/goals/${id}`)
  },
  getTask (id) {
    return this.execute('get', `/tasks/${id}`)
  },
  createGoal (data) {
    return this.execute('post', '/goals', data)
  },
  createTask (data) {
    return this.execute('post', '/tasks', data)
  },
  updateGoal (id, data) {
    return this.execute('put', `/goals/${id}`, data)
  },
  updateTask (id, data) {
    return this.execute('put', `/tasks/${id}`, data)
  },
  deleteGoal (id) {
    return this.execute('delete', `/goals/${id}`)
  },
  deleteTask (id) {
    return this.execute('delete', `/tasks/${id}`)
  }
}
