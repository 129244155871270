<template>
  <div>
    <md-steppers :md-active-step.sync="active"  md-alternative>
      <md-step id="first" md-label="Define goals" :md-done.sync="first">
        <div v-if="!haveGoals()">
          <md-empty-state
            md-icon="add_chart"
            md-label="Create your first goal"
            md-description="What is really important to your organisation">
            <md-button class="md-primary md-raised" @click="showGoalDialog = true">Create first goal</md-button>
          </md-empty-state>
        </div>
        <div class="md-layout md-gutter" v-else>
          <md-card class="md-layout-item md-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100" v-for="goal in goals" :key="goal.id">
            <md-card-header>
              <div class="md-title">Goal: {{goal.goal_description}}</div>
              <div class="md-subhead">{{goal.measurement_description}}</div>
            </md-card-header>
            <md-card-content>
              <md-table>
                <md-table-row slot="md-table-row">
                  <md-table-cell>Minimum</md-table-cell>
                  <md-table-cell>{{goal.minimum_value}}</md-table-cell>
                </md-table-row>
                <md-table-row slot="md-table-row">
                  <md-table-cell>Current</md-table-cell>
                  <md-table-cell>{{goal.current_value}}</md-table-cell>
                </md-table-row>
                <md-table-row slot="md-table-row">
                  <md-table-cell>Target</md-table-cell>
                  <md-table-cell>{{goal.target_value}}</md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
            <md-card-actions>
              <md-button href="#" @click.prevent="deleteGoal(goal.id)">Delete</md-button>
            </md-card-actions>
          </md-card>
        </div>
        <md-button v-if="haveGoals()" class="md-primary md-raised" @click="showGoalDialog = true">Create extra goal</md-button>
        <md-button class="md-raised" @click="setDone('first', 'second')">Continue</md-button>
      </md-step>

      <md-step id="second" md-label="Define tasks" :md-done.sync="second">
        <div v-if="!haveTasks()">
          <md-empty-state
            md-icon="add_task"
            md-label="Create your first task"
            md-description="Write down tasks and estimate their impact on your goals">
            <md-button class="md-primary md-raised" @click="showTaskDialog = true">Create first task</md-button>
          </md-empty-state>
        </div>
        <div v-else>
          <md-table md-card>
            <md-table-toolbar>
              <h1 class="md-title">Task list</h1>
            </md-table-toolbar>
            <md-table-row>
              <md-table-head md-numeric>ID</md-table-head>
              <md-table-head>Description</md-table-head>
              <md-table-head md-numeric>Size</md-table-head>
              <md-table-head v-for="goal in goals" :key="goal.id" md-numeric>Impact on {{goal.goal_description}}</md-table-head>
            </md-table-row>
            <md-table-row v-for="task in tasks" :key="task.id">
              <md-table-cell md-numeric>{{ task.id }}</md-table-cell>
              <md-table-cell>{{ task.task_description }}</md-table-cell>
              <md-table-cell md-numeric>{{ task.size }}</md-table-cell>
              <md-table-cell v-for="goal in goals" :key="goal.id" md-numeric>{{ task.impact_on_goals[goal.id] }}</md-table-cell>
              <md-table-cell>
                <md-button class="md-icon-button" href="#" @click.prevent="deleteTask(task.id)">
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <md-button v-if="haveTasks()" class="md-primary md-raised" @click="showTaskDialog = true">Create extra task</md-button>
        <md-button class="md-raised" @click="setDone('second', 'third')">Done</md-button>
      </md-step>

      <md-step id="third" md-label="See your priorities" :md-done.sync="third">
        <div class="md-layout md-gutter" v-if="haveGoals()">
          <md-card class="md-layout-item md-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100" v-for="goal in goals" :key="goal.id">
            <md-card-header>
              <div class="md-title">Goal: {{goal.goal_description}}</div>
              <div class="md-subhead">{{goal.measurement_description}}</div>
            </md-card-header>
            <md-card-content>
              <md-table>
                <md-table-row slot="md-table-row">
                  <md-table-cell>Minimum</md-table-cell>
                  <md-table-cell>{{goal.minimum_value}}</md-table-cell>
                </md-table-row>
                <md-table-row slot="md-table-row">
                  <md-table-cell>Current</md-table-cell>
                  <md-table-cell>{{goal.current_value}}</md-table-cell>
                </md-table-row>
                <md-table-row slot="md-table-row">
                  <md-table-cell>Target</md-table-cell>
                  <md-table-cell>{{goal.target_value}}</md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </div>
        <md-table v-model="tasks" md-sort="id" md-sort-order="asc" md-card>
          <md-table-toolbar>
            <h1 class="md-title">Task list</h1>
          </md-table-toolbar>
          <!-- <md-table-row>
            <md-table-head @click.native="sort('id')" md-numeric>ID</md-table-head>
            <md-table-head @click.native="sort('task_description')">Description</md-table-head>
            <md-table-head @click.native="sort('size')" md-numeric>Size</md-table-head>
            <md-table-head v-for="goal in goals" :key="goal.id" @click.native="sort('impact_on_goals[' + goal.id + ']')" md-numeric>Impact on {{goal.goal_description}}</md-table-head>
          </md-table-row> -->
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="Description" md-sort-by="task_description">{{ item.task_description }}</md-table-cell>
            <md-table-cell md-label="Size" md-sort-by="size" md-numeric>{{ item.size }}</md-table-cell>
            <md-table-cell v-for="goal in goals" :key="goal.id" v-bind:md-label="goalDescription(goal)" v-bind:md-sort-by="item.impact_on_goals[goal.id]" md-numeric>{{ item.impact_on_goals[goal.id] }}</md-table-cell>
          </md-table-row>
        </md-table>
      </md-step>
    </md-steppers>

    <div>
      <md-dialog :md-active.sync="showGoalDialog">
        <form @submit.prevent="saveGoal">
          <md-dialog-title>Create goal</md-dialog-title>
          <md-field class="md-layout-item">
            <label>Goal description</label>
            <md-input type="text" v-model="goalmodel.goal_description"></md-input>
          </md-field>
          <md-field class="md-layout-item">
            <label>How to measure</label>
            <md-input type="text" v-model="goalmodel.measurement_description"></md-input>
          </md-field>
          <div class="md-layout">
            <md-field class="md-layout-item">
              <label>Minumum value</label>
              <md-input type="number" v-model="goalmodel.minimum_value"></md-input>
            </md-field>
            <md-field class="md-layout-item">
              <label>Current value</label>
              <md-input type="number" v-model="goalmodel.current_value"></md-input>
            </md-field>
            <md-field class="md-layout-item">
              <label>Target value</label>
              <md-input type="number" v-model="goalmodel.target_value"></md-input>
            </md-field>
          </div>
          <md-dialog-actions>
            <md-button class="md-primary" @click="showGoalDialog = false">Close</md-button>
            <md-button type="submit" class="md-primary">Save</md-button>
          </md-dialog-actions>
        </form>
      </md-dialog>
    </div>

    <div>
      <md-dialog :md-active.sync="showTaskDialog">
        <form @submit.prevent="saveTask">
          <md-dialog-title>Create task</md-dialog-title>
          <md-field class="md-layout-item">
            <label>Task description</label>
            <md-input type="text" v-model="taskmodel.task_description"></md-input>
          </md-field>
          <div class="md-layout">
            <md-field class="md-layout-item">
              <label>Size</label>
              <md-input type="number" v-model="taskmodel.size"></md-input>
            </md-field>
            <div v-for="goal in goals" :key="goal.id" >
              <md-field class="md-layout-item">
                <label>{{goalDescription(goal)}}</label>
                <md-input type="number" v-model=taskmodel.impact_on_goals[goal.id]></md-input>
              </md-field>
            </div>
          </div>
          <md-dialog-actions>
            <md-button class="md-primary" @click="showTaskDialog = false">Close</md-button>
            <md-button type="submit" class="md-primary">Save</md-button>
          </md-dialog-actions>
        </form>
      </md-dialog>
    </div>
</div>
</template>

<script>
import api from '../server/api'
export default {
  name: 'Start',
  data () {
    return {
      showTaskDialog: false,
      showGoalDialog: false,
      goals: [],
      goalmodel: {},
      tasks: [],
      taskmodel: {
        task_description: null,
        size: null,
        impact_on_goals: []
      },
      active: 'first',
      first: false,
      second: false,
      third: false,
      secondStepError: null
    }
  },
  async created () {
    this.refreshGoals()
    this.refreshTasks()
  },
  methods: {
    goalDescription (goal) {
      return 'Impact on: ' + goal.goal_description
    },
    haveGoals () {
      return this.goals.length > 0
    },
    haveTasks () {
      return this.tasks.length > 0
    },
    setDone (id, index) {
      this[id] = true
      this.secondStepError = null

      if (index) {
        this.active = index
      }
    },
    async refreshGoals () {
      this.goals = await api.getGoals()
    },
    async refreshTasks () {
      this.tasks = await api.getTasks()
    },
    async saveGoal () {
      if (this.goalmodel.id) {
        await api.updateGoal(this.goalmodel.id, this.goalmodel)
      } else {
        await api.createGoal(this.goalmodel)
      }
      this.goalmodel = {} // reset form
      await this.refreshGoals()
    },
    async saveTask () {
      if (this.taskmodel.id) {
        await api.updateTask(this.taskmodel.id, this.taskmodel)
      } else {
        await api.createTask(this.taskmodel)
      }
      this.taskmodel = {
        impact_on_goals: []
      } // reset form
      await this.refreshTasks()
    },
    async deleteGoal (id) {
      if (confirm('Are you sure you want to delete this goal?')) {
        // if we are editing a goal we deleted, remove it from the form
        if (this.goalmodel.id === id) {
          this.goalmodel = {}
        }
        await api.deleteGoal(id)
        await this.refreshGoals()
      }
    },
    async deleteTask (id) {
      if (confirm('Are you sure you want to delete this task?')) {
        // if we are editing a task we deleted, remove it from the form
        if (this.taskmodel.id === id) {
          this.taskmodel = {}
        }
        await api.deleteTask(id)
        await this.refreshTasks()
      }
    }
  },
  computed: {
    sortedTasks () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.tasks.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') modifier = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier
        return 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  padding: 1em;
}
.md-table {
  margin-top: 1em;
}

</style>
